import React, { useEffect } from "react";
import Login from "../components/pages/Login";
import MinimalLayout from "../components/layout/MinimalLayout";
import { useIsLogedInState } from "globalStates/isLogedInState";
import { useUser } from "models/UserModel";

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const { isLogedIn, setIsLogedIn } = useIsLogedInState();
  const { getUser } = useUser();
  const user = getUser();

  useEffect(() => {
    const isLogedInFlag = isLogedIn || user ? true : false;
    isLogedInFlag && !isLogedIn && setIsLogedIn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLogedIn ? (
    children
  ) : (
    <MinimalLayout>
      <Login />
    </MinimalLayout>
  );
};

export default AuthProvider;
