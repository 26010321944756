import { Box, Button, FormLabel } from "@mui/material";
import { FC, useCallback, useRef, useState } from "react";

type TProps = {
  name: string;
  title?: string;
  defaultUrl: string | undefined;
  defaultFileId: number | undefined;
  uploader: (file: File) => Promise<any>;
};

const StdImageFile: FC<TProps> = ({ name, title, defaultUrl, defaultFileId, uploader }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [fid, setFid] = useState(defaultFileId);
  const [src, setSrc] = useState(defaultUrl);

  const onClickButton = useCallback(() => {
    if (src) {
      setFid(0);
      setSrc("");
    } else fileRef.current && fileRef.current.click();
  }, [src]);

  const onChangeFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files?.length === 1) {
        const result = await uploader(files[0]);
        setFid(result.data.file_id);
        setSrc(result.data.url);
      }
    },
    [uploader],
  );

  return (
    <>
      {title && <FormLabel>{title}</FormLabel>}
      <Box sx={{ display: "flex", alignItems: "end" }}>
        <img src={src} alt="" style={{ maxWidth: "100px", maxHeight: "100px" }} />
        <Box sx={{ pl: 2 }}>
          <Button onClick={onClickButton} variant="outlined">
            {src ? "Delete" : "Select"}
          </Button>
        </Box>
        <input type="file" ref={fileRef} hidden name={name} accept="image/*" onChange={onChangeFile} />
        <input type="hidden" name={`${name}_file_id`} value={fid} />
      </Box>
    </>
  );
};

export default StdImageFile;
