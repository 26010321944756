import { FC } from "react";
import { Box } from "@mui/material";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import LeftNavMenu from "./LeftNavMenu";

const LeftNavContent: FC<{ simpleBarHeight: number }> = ({ simpleBarHeight }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <SimpleBar style={{ height: simpleBarHeight }}>
        <LeftNavMenu />
      </SimpleBar>
    </Box>
  );
};

export default LeftNavContent;
