import React, { useEffect } from "react";
import { useUser } from "models/UserModel";
import { useInitMenus } from "models/MenuModel";
import { useInitRoutes } from "models/RouteModel";
import { useInitCards } from "models/CardModel";
import { useInitCardLayouts } from "models/CardLayoutModel";

const UserInitializer = ({ children }: { children: React.ReactElement }) => {
  const { getUser } = useUser();
  const user = getUser();

  const initMenus = useInitMenus();
  const initRoutes = useInitRoutes();
  const initCards = useInitCards();
  const initCardLayouts = useInitCardLayouts();

  useEffect(() => {
    initMenus(user);
    const routes = initRoutes(user);
    initCards(user);
    initCardLayouts(user, routes);
  }, [initCardLayouts, initCards, initMenus, initRoutes, user]);

  return children;
};

export default UserInitializer;
