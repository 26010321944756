import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue } from "recoil";
import { TUser } from "./UserModel";

export type TMenu = {
  id: number;
  type?: "menu";
  title: string;
};

const menuState = atomFamily<TMenu, TMenu["id"]>({
  key: "menuState",
  default: undefined,
});

export type TMenuIds = TMenu["id"][];
const menuIdsState = atom<TMenuIds>({
  key: "menuIdsState",
  default: [],
});

export const allMenusSelector = selector<TMenu[]>({
  key: "allMenusSelector",
  get: ({ get }) => {
    const ids = get(menuIdsState);
    return ids.map((menuId) => get(menuState(menuId)));
  },
});

export const useInitMenus = () => {
  const initMenus = useRecoilCallback(() => (user: TUser) => {
    const menus = user?.menus as TMenu[];
    menus && registMenus(menus);
  });

  const registMenus = useRecoilCallback(({ set }) => (menus: TMenu[]) => {
    const menuIds: TMenuIds = [];
    menus.forEach((menu: TMenu) => registMenu({ menu, menuIds }));
    set(menuIdsState, menuIds);
  });

  type TArgs = {
    menu: TMenu;
    menuIds: TMenuIds;
  };
  const registMenu = useRecoilCallback(({ set }) => ({ menu, menuIds }: TArgs) => {
    set(menuState(menu.id), menu);
    menuIds.push(menu.id);
  });

  return initMenus;
};

export const useAllMenus = () => {
  return useRecoilValue(allMenusSelector);
};
