import { atom, useRecoilValue, useSetRecoilState } from "recoil";

const isLogedInState = atom({
  key: "isLogedInState",
  default: false,
});

export const useIsLogedInState = () => {
  const isLogedIn = useRecoilValue(isLogedInState);
  const setIsLogedIn = useSetRecoilState(isLogedInState);
  return { isLogedIn, setIsLogedIn };
};
