import { FC, useEffect, useRef } from "react";
import { AppBar, Badge, IconButton, Toolbar, useTheme } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import { drawerWidth } from "config";
import { useLeftNavOpen } from "globalStates/leftNavState";
import useHeaderHeight from "globalStates/headerHeightState";
import { Notifications } from "@mui/icons-material";
import AccountMenu from "./AccountMenu";
import HeadTitle from "./HeadTitle";

const Header: FC = () => {
  const theme = useTheme();

  const { isOpen, setToggle } = useLeftNavOpen();

  const wrapToolbar = useRef<HTMLDivElement>(null);
  const { setHeaderHeight } = useHeaderHeight();
  useEffect(() => {
    wrapToolbar.current && setHeaderHeight(wrapToolbar.current.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar
      sx={{
        paddingLeft: isOpen ? `${drawerWidth}px` : 0,
        transition: theme.transitions.create("padding", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <div ref={wrapToolbar}>
        <Toolbar>
          <IconButton onClick={setToggle} sx={{ color: "inherit", margin: "0 8px 0 -8px" }}>
            <MenuOpenIcon
              sx={{
                transform: isOpen ? 1 : "scale(-1, 1)",
              }}
            />
          </IconButton>
          <HeadTitle />
          <IconButton color="inherit">
            <Badge badgeContent={4} color="warning">
              <Notifications />
            </Badge>
          </IconButton>
          <AccountMenu />
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default Header;
