import { useSortable } from "@dnd-kit/sortable";
import { ListSubheader } from "@mui/material";
import { FC } from "react";
import { CSS } from "@dnd-kit/utilities";
import { TMenu } from "models/MenuModel";

type TProps = {
  item: TMenu;
  sx?: object;
};

const DndLeftNavMenuTitle: FC<TProps> = ({ item, sx = {} }) => {
  const { setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <ListSubheader component="div" id={`menu_${item.id}`}>
        {item.title}
      </ListSubheader>
    </div>
  );
};

export default DndLeftNavMenuTitle;
