import { TextField } from "@mui/material";
import StdFormDialog from "components/common/form/StdFormDialog";
import StdImageFile from "components/common/form/StdImageFile";
import { useUser } from "models/UserModel";
import { FC, useCallback } from "react";

type TProps = {
  openState: boolean;
  handleOnClose: () => void;
};

const AccountEditFormDialog: FC<TProps> = ({ openState, handleOnClose }) => {
  const { getUser, patchUser, uploadUserAvatar } = useUser();
  const user = getUser();

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const newData = {
        first_name: data.get("first_name"),
        last_name: data.get("last_name"),
        avatar_file_id: data.get("avatar_file_id"),
      };
      await patchUser(newData);
      handleOnClose();
    },
    [handleOnClose, patchUser],
  );

  return (
    <StdFormDialog
      title="Edit Account Form"
      openState={openState}
      handleOnSubmit={handleOnSubmit}
      handleOnClose={handleOnClose}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="first_name"
        label="First Name"
        name="first_name"
        autoComplete="first_name"
        autoFocus
        defaultValue={user?.firstName}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="last_name"
        label="Last Name"
        name="last_name"
        autoComplete="last_name"
        autoFocus
        defaultValue={user?.lastName}
      />
      <StdImageFile
        name="avatar"
        title="Avatar Image"
        defaultUrl={user?.avatar_url}
        defaultFileId={user?.avatar_file_id}
        uploader={uploadUserAvatar}
      />
    </StdFormDialog>
  );
};

export default AccountEditFormDialog;
