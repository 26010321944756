import { atom, useRecoilValue, useSetRecoilState } from "recoil";

const headerHeightState = atom({
  key: "headerHeightState",
  default: 0,
});

const useHeaderHeight = () => {
  const headerHeight = useRecoilValue(headerHeightState);
  const setHeaderHeight = useSetRecoilState(headerHeightState);

  return { headerHeight, setHeaderHeight };
};

export default useHeaderHeight;
