import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";

type TProps = {
  children?: React.ReactNode;
  title?: string;
  text?: string;
  openState: boolean;
  handleOnSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleOnClose: () => void;
};

const StdFormDialog: FC<TProps> = ({
  title,
  text,
  openState,
  handleOnSubmit,
  handleOnClose,
  children,
}) => {
  return (
    <Dialog
      open={openState}
      onClose={handleOnClose}
      PaperProps={{
        sx: { minWidth: "50%" },
        component: "form",
        onSubmit: handleOnSubmit,
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} variant="outlined">
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StdFormDialog;
