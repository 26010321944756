import { FC } from "react";
import { CustomThemeProvider } from "./CustomThemeProvider";
import AuthProvider from "./AuthProvider";
import Router from "./Router";
import UserInitializer from "./UserInitializer";

export const CockpitRoot: FC = () => {
  return (
    <CustomThemeProvider>
      <AuthProvider>
        <UserInitializer>
          <Router />
        </UserInitializer>
      </AuthProvider>
    </CustomThemeProvider>
  );
};
