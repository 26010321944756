import { useIsLogedInState } from "globalStates/isLogedInState";
import { apiSignIn, apiSignOut } from "modules/Drupal/Drupal";
import { useResetUser } from "./UserModel";

type AuthData = {
  csrf_token: string;
  logout_token: string;
  current_user: {
    name: string;
    uid: number;
  };
} | null;

const getAuthData = (): AuthData => {
  const data = localStorage.getItem("authData");
  return data && JSON.parse(data);
};

const setAuthData = (authData: AuthData) => {
  localStorage.setItem("authData", JSON.stringify(authData));
};

const useAuth = () => {
  const { setIsLogedIn } = useIsLogedInState();
  const resetUser = useResetUser();

  const flushAuthData = () => {
    localStorage.removeItem("authData");
    resetUser();
  };

  const signIn = async (
    userName: FormDataEntryValue | null,
    password: FormDataEntryValue | null,
  ) => {
    const result = await apiSignIn(userName, password);

    if (result.status === "success") {
      const authData = result.response.data;
      setAuthData(authData);
      setIsLogedIn(true);
    } else {
      flushAuthData();
      setIsLogedIn(false);
      alert("error");
    }

    return result;
  };

  const signOut = async () => {
    const authData = getAuthData();
    const result = await apiSignOut(authData);

    if (result.status === "success") {
      flushAuthData();
      setIsLogedIn(false);
    } else {
      alert("error");
    }

    return result;
  };

  return { signIn, signOut };
};

export type { AuthData };

export { getAuthData, useAuth };
