import { FC, ReactElement } from "react";

// UI Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Logout from "@mui/icons-material/Logout";
// Mail Icons
import Inbox from "@mui/icons-material/Inbox";
import Mail from "@mui/icons-material/Mail";
// Dashboard Icons
import Dashboard from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import MoreVert from "@mui/icons-material/MoreVert";
import Clear from "@mui/icons-material/Clear";
// Misc Icons
import Home from "@mui/icons-material/Home";
import StarBorder from "@mui/icons-material/StarBorder";

interface iconListInterface {
  [key: string]: ReactElement;
}

const iconList: iconListInterface = {
  ExpandLess: <ExpandLess />,
  ExpandMore: <ExpandMore />,
  Logout: <Logout />,
  Inbox: <Inbox />,
  Mail: <Mail />,
  Dashboard: <Dashboard />,
  Settings: <Settings />,
  MoreVert: <MoreVert />,
  Clear: <Clear />,
  Home: <Home />,
  StarBorder: <StarBorder />,
};

const Icons: FC<{ iconName: string }> = ({ iconName }) => {
  return iconList[iconName];
};

export default Icons;
