import { Typography } from "@mui/material";
import { useRouteStates } from "models/RouteModel";

const HeadTitle = () => {
  const { currentRoute } = useRouteStates();

  return (
    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
      {currentRoute?.title}
    </Typography>
  );
};

export default HeadTitle;
