import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";

// import drawerWidth

const LeftNavBottom = () => {
  return (
    <Card
      sx={{
        backgroundColor: "grey.100",
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be・nev・o・lent
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
        <Typography variant="body2">
          <a href="https://poc.webfocus.cips.jp/cockpit/" target="_blank" rel="noreferrer">
            Drupal
          </a>
        </Typography>
        <Typography variant="body2">
          <a href="https://poc.webfocus.cips.jp/ibi_apps/signin" target="_blank" rel="noreferrer">
            WEBFocus
          </a>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
};

export default LeftNavBottom;
