import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { TUser } from "./UserModel";
import { TRoute } from "./RouteModel";
import { TCard } from "./CardModel";

const defaultLayouts = { xl: [], lg: [], md: [], sm: [], xs: [] };

type TRouteCardLayouts = {
  route_id: number;
  layouts: ReactGridLayout.Layouts;
};

const routeLayoutsState = atomFamily<TRouteCardLayouts, TRouteCardLayouts["route_id"]>({
  key: "layoutsState",
  default: undefined,
});

export type TRouteLayoutIds = TRouteCardLayouts["route_id"][];
const routeLayoutIdsState = atom<TRouteLayoutIds>({
  key: "routeLayoutIdsState",
  default: [],
});

const allRouteLayoutsSelector = selector<TRouteCardLayouts[]>({
  key: "allRouteLayoutsSelector",
  get: ({ get }) => {
    const ids = get(routeLayoutIdsState);
    return ids.map((routeId) => get(routeLayoutsState(routeId)));
  },
});

export const useInitCardLayouts = () => {
  const initLayouts = useRecoilCallback(() => (user: TUser, routes: TRoute[]) => {
    let layouts = user?.layouts as TRouteCardLayouts[];
    layouts = Array.isArray(layouts)
      ? layouts
      : routes
        ? routes
            .filter((route) => route.page === "Dashboard")
            .map((route) => {
              return {
                route_id: route.id,
                layouts: defaultLayouts,
              };
            })
        : [];
    registLayouts(layouts);
  });

  const registLayouts = useRecoilCallback(({ set }) => (layouts: TRouteCardLayouts[]) => {
    const routeIds: TRouteLayoutIds = [];
    layouts.forEach((layout: TRouteCardLayouts) => {
      set(routeLayoutsState(layout.route_id), layout);
      routeIds.push(layout.route_id);
    });
    set(routeLayoutIdsState, routeIds);
  });

  return initLayouts;
};

export const useCardLayouts = () => {
  const getAllCardLayouts = useRecoilCallback(({ snapshot }) => async () => {
    const layouts: TRouteCardLayouts[] = await snapshot.getPromise(allRouteLayoutsSelector);
    return layouts;
  });

  const addCardLayout = useRecoilCallback(({ snapshot, set }) => async (routeId: TRoute["id"], newLayout: any) => {
    const routeLayoutes = await snapshot.getPromise(routeLayoutsState(routeId));
    const newRouteLayouts: TRouteCardLayouts = JSON.parse(JSON.stringify(routeLayoutes));
    Object.keys(newRouteLayouts.layouts).forEach((key) => {
      newRouteLayouts.layouts[key] = newRouteLayouts.layouts[key].map((layout) =>
        layout.i === newLayout.i ? newLayout : layout,
      );
    });
    set(routeLayoutsState(routeId), newRouteLayouts);
  });

  const deleteCardLayout = useRecoilCallback(
    ({ snapshot, set }) =>
      async (routeId: TRoute["id"], uuid: TCard["uuid"]) => {
        const routeLayoutes = await snapshot.getPromise(routeLayoutsState(routeId));
        const newRouteLayouts: TRouteCardLayouts = JSON.parse(JSON.stringify(routeLayoutes));
        Object.keys(newRouteLayouts.layouts).forEach((key) => {
          const newLayout = newRouteLayouts.layouts[key].filter((layout) => layout.i !== uuid);
          newRouteLayouts.layouts[key] = newLayout;
        });
        set(routeLayoutsState(routeId), newRouteLayouts);
      },
  );

  return { getAllCardLayouts, addCardLayout, deleteCardLayout };
};

export const useSetRouteCardLayouts = (route_id: TRoute["id"]) => {
  return useSetRecoilState(routeLayoutsState(route_id));
};

export const useRouteCardLayouts = (routeId: TRoute["id"]) => {
  return useRecoilValue(routeLayoutsState(routeId));
};
