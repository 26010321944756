import { FC, useEffect, useRef, useState } from "react";
import { Box, Drawer, Toolbar, Typography, useTheme } from "@mui/material";

import { drawerWidth } from "config";
import { useLeftNavOpen } from "globalStates/leftNavState";
import LeftNavContent from "./LeftNavContent";
import LeftNavBottom from "./LeftNavBottom";
import useHeaderHeight from "globalStates/headerHeightState";
import { useNavigate } from "react-router-dom";

const LeftNav: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { isOpen } = useLeftNavOpen();

  const { headerHeight } = useHeaderHeight();
  const [simpleBarHeight, setSimpleBarHeight] = useState(window.innerHeight - headerHeight);
  const wrapBottom = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const setHeight = () => {
      wrapBottom.current && setSimpleBarHeight(window.innerHeight - headerHeight - wrapBottom.current.clientHeight);
    };
    setHeight();
    window.addEventListener("resize", setHeight);
    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, [headerHeight]);

  return (
    <Box component="nav" sx={{ flexShrink: 0 }}>
      <Drawer
        variant="persistent"
        open={isOpen}
        sx={{
          width: isOpen ? drawerWidth : 0,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Toolbar
            sx={{
              cursor: "pointer",
              paddingLeft: "16px !important",
              "&:hover": { backgroundColor: "grey.200" },
            }}
            onClick={() => navigate("/")}
          >
            <Typography component="h1" variant="h6" noWrap>
              COCKPIT
            </Typography>
          </Toolbar>
          <LeftNavContent simpleBarHeight={simpleBarHeight} />
          <div ref={wrapBottom}>
            <LeftNavBottom />
          </div>
        </Box>
      </Drawer>
    </Box>
  );
};

export default LeftNav;
