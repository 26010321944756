import {
  atom,
  atomFamily,
  selector,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { TUser } from "./UserModel";
import { TRoute } from "./RouteModel";

export type TCard = {
  uuid: string;
  route_id?: any;
  card_master_id: number;
  card_title?: string;
};

const cardState = atomFamily<TCard, TCard["uuid"]>({
  key: "cardState",
  default: undefined,
});

export type TCardIds = string[];
const cardIdsState = atom<TCardIds>({
  key: "cardIdsState",
  default: [],
});

type TRouteCardIds = TCard["uuid"][];
type TRouteId = TRoute["id"];
const routeCardIdsState = atomFamily<TRouteCardIds, TRouteId>({
  key: "routeCardIdsState",
  default: [],
});

const allCardsSelector = selector<TCard[]>({
  key: "allCardsSelector",
  get: ({ get }) => {
    const ids = get(cardIdsState);
    return ids.map((cardId) => get(cardState(cardId)));
  },
});

export const useInitCards = () => {
  const initCards = useRecoilCallback(() => (user: TUser) => {
    const routes = user?.routes as TRoute[];
    const cards = user?.cards as TCard[];

    routes && cards && initRouteCardIds({ routes, cards });
    cards && registCards(cards);
  });

  type TArgs = {
    routes: TRoute[];
    cards: TCard[];
  };
  const initRouteCardIds = useRecoilCallback(({ reset }) => ({ routes, cards }: TArgs) => {
    const cardRoutes = cards.map((card) => card.route_id);
    routes
      .filter((route) => cardRoutes.indexOf(route.id) < 0)
      .forEach((route) => reset(routeCardIdsState(route.id)));
  });

  const registCards = useRecoilCallback(({ set }) => (cards: TCard[]) => {
    const cardIds: TCardIds = [];
    const routeCardIds: { [key: string]: TRouteCardIds } = {};

    cards.forEach((card: TCard) => registCard({ card, cardIds, routeCardIds }));

    set(cardIdsState, cardIds);
    Object.keys(routeCardIds).forEach((routeId: any) => {
      set(routeCardIdsState(Number(routeId)), routeCardIds[routeId]);
    });
  });

  type TArgs2 = {
    card: TCard;
    cardIds: TCardIds;
    routeCardIds: { [key: string]: TRouteCardIds };
  };
  const registCard = useRecoilCallback(({ set }) => ({ card, cardIds, routeCardIds }: TArgs2) => {
    set(cardState(card.uuid), card);
    cardIds.push(card.uuid);
    if (!routeCardIds[card.route_id]) routeCardIds[card.route_id] = [];
    routeCardIds[card.route_id].push(card.uuid);
  });

  return initCards;
};

export const useCards = () => {
  const getAllCards = useRecoilCallback(({ snapshot }) => async () => {
    const cards: TCard[] = await snapshot.getPromise(allCardsSelector);
    return cards;
  });

  const getRouteCardIds = useRecoilCallback(({ snapshot }) => async (routeId: TRouteId) => {
    const cardIds: TRouteCardIds = await snapshot.getPromise(routeCardIdsState(routeId));
    return cardIds;
  });

  const addCard = useRecoilCallback(
    ({ snapshot, set }) =>
      async (routeId: TRouteId, newCard: TCard) => {
        const cardIds = [...(await snapshot.getPromise(cardIdsState))];
        const routeCardIds = [...(await snapshot.getPromise(routeCardIdsState(routeId)))];
        cardIds.push(newCard.uuid);
        routeCardIds.push(newCard.uuid);
        set(cardIdsState, cardIds);
        set(routeCardIdsState(routeId), routeCardIds);
        set(cardState(newCard.uuid), newCard);
      },
  );

  const deleteCard = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (routeId: TRouteId, uuid: TCard["uuid"]) => {
        const cardIds = [...(await snapshot.getPromise(cardIdsState))];
        const routeCardIds = [...(await snapshot.getPromise(routeCardIdsState(routeId)))];
        const newCardIds = cardIds.filter((id) => id !== uuid);
        const newRouteCardIds = routeCardIds.filter((id) => id !== uuid);
        set(cardIdsState, newCardIds);
        set(routeCardIdsState(routeId), newRouteCardIds);
        reset(cardState(uuid));
      },
  );

  return { getAllCards, getRouteCardIds, addCard, deleteCard };
};

export const useCard = (uuid: TCard["uuid"]) => {
  return useRecoilValue(cardState(uuid));
};

export const useSetCard = (uuid: TCard["uuid"]) => {
  return useSetRecoilState(cardState(uuid));
};

export const useRouteCardIds = (routeId: TRouteId) => {
  return useRecoilValue(routeCardIdsState(routeId));
};
