import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Icons from "components/common/Icons";
import { TRoute, useRouteStates } from "models/RouteModel";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DragHandle from "@mui/icons-material/DragHandle";
import { DraggableAttributes, DraggableSyntheticListeners } from "@dnd-kit/core";
import { useLeftNavItems, useLeftNavOpenFolders } from "globalStates/leftNavState";

type TProps = {
  item: TRoute;
  dragHandleProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
  };
};

const DndLeftNavItem: FC<TProps> = ({ item, dragHandleProps }) => {
  const navigate = useNavigate();
  const { setCurrentRoute } = useRouteStates();

  const depth = item.depth ? item.depth : 0;

  const { setClose, isOpen: isOpenParent } = useLeftNavOpenFolders();
  const { setItemsOnCollapse } = useLeftNavItems();
  const isOpen = isOpenParent(item.id);

  const onClickItem = useCallback(
    (item: TRoute) => {
      if (item.type === "folder") {
        isOpen ? setClose(item.id) : setItemsOnCollapse(item.id);
      } else {
        item.url && navigate(item.url);
        setCurrentRoute(item);
      }
    },
    [isOpen, navigate, setClose, setCurrentRoute, setItemsOnCollapse],
  );

  return (
    <ListItemButton sx={{ pl: (depth + 1) * 2 }} onClick={() => onClickItem(item)}>
      <ListItemIcon>{item.icon && <Icons iconName={item.icon} />}</ListItemIcon>
      <ListItemText primary={`${item.title} | ${item.id}`} />
      {item.hasChildren && (isOpen ? <Icons iconName="ExpandLess" /> : <Icons iconName="ExpandMore" />)}
      <Box
        ref={dragHandleProps?.ref}
        {...dragHandleProps?.attributes}
        {...dragHandleProps?.listeners}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: dragHandleProps ? "grab" : "grabbing",
        }}
      >
        <DragHandle />
      </Box>
    </ListItemButton>
  );
};

export default DndLeftNavItem;
