import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { TUser } from "./UserModel";

export type TRoute = {
  id: number;
  type?: "route" | "folder";
  depth?: number;
  parentId?: number | undefined;
  hasChildren?: boolean;
  title?: string | undefined;
  url?: string | undefined;
  page?: string | undefined;
  icon?: string;
  children?: TRoute[];
  menuId: number;
};

const routeState = atomFamily<TRoute, TRoute["id"]>({
  key: "routeState",
  default: undefined,
});

const currentRouteState = atom<TRoute>({
  key: "currentRouteState",
  default: undefined,
});

export type TRouteIds = TRoute["id"][];
const routeIdsState = atom<TRouteIds>({
  key: "routeIdsState",
  default: [],
});

export const allRoutesSelector = selector<TRoute[]>({
  key: "allRoutesSelector",
  get: ({ get }) => {
    const ids = get(routeIdsState);
    return ids.map((routeId) => get(routeState(routeId)));
  },
});

const routesTreeSelector = selector<TRoute[]>({
  key: "routesTreeSelector",
  get: ({ get }) => {
    const routes = get(allRoutesSelector);
    const tree: TRoute[] = [];
    routes.forEach((route) => {
      if (!route.parentId) {
        const newRoute = { ...route };
        if (route.hasChildren) newRoute.children = routes.filter((child) => route.id === child.parentId);
        tree.push(newRoute);
      }
    });
    return tree;
  },
});

export const useInitRoutes = () => {
  const initRoutes = useRecoilCallback(({ set }) => (user: TUser) => {
    const routes = user?.routes as TRoute[];
    routes && registRoutes(routes);
    const currentRoute = routes ? routes.find((route) => route.url === window.location.pathname) : undefined;
    currentRoute && set(currentRouteState, currentRoute);
    return routes;
  });

  const registRoutes = useRecoilCallback(({ set }) => (routes: TRoute[]) => {
    const routeIds: TRouteIds = [];
    routes.forEach((route: TRoute) => registRoute({ route, routeIds }));
    set(routeIdsState, routeIds);
  });

  type TArgs = {
    route: TRoute;
    routeIds: TRouteIds;
  };
  const registRoute = useRecoilCallback(({ set }) => ({ route, routeIds }: TArgs) => {
    set(routeState(route.id), route);
    routeIds.push(route.id);
  });

  return initRoutes;
};

export const useRouteStates = () => {
  const allRoutes = useRecoilValue(allRoutesSelector);
  const routeIds = useRecoilValue(routeIdsState);
  const routesTree = useRecoilValue(routesTreeSelector);
  const currentRoute = useRecoilValue(currentRouteState);
  const setCurrentRoute = useSetRecoilState(currentRouteState);

  const setRoute = useRecoilCallback(({ set }) => (route: TRoute) => {
    set(routeState(route.id), route);
  });

  const setRouteIds = useRecoilCallback(({ set }) => (routeIds: number[]) => {
    set(routeIdsState, routeIds);
  });

  return { allRoutes, routeIds, routesTree, currentRoute, setRoute, setRouteIds, setCurrentRoute };
};
