import { useSortable } from "@dnd-kit/sortable";
import { FC, useEffect, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useLeftNavItems, useLeftNavOpenFolders } from "globalStates/leftNavState";
import { Collapse } from "@mui/material";
import { TRoute } from "models/RouteModel";
import DndLeftNavItem from "./DndLeftNavItem";

type TProps = {
  item: TRoute;
};

const DndLeftNavItemSortable: FC<TProps> = ({ item }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging, setActivatorNodeRef } = useSortable({
    id: item.id,
  });

  const { isOpen: isOpenParent } = useLeftNavOpenFolders();
  const [isOpen, setIsOpen] = useState(item.parentId ? false : true);
  const { setItemsOnCollapse } = useLeftNavItems();

  useEffect(() => {
    item.parentId && setIsOpen(isOpenParent(item.parentId));
  }, [isOpenParent, item.parentId]);

  return (
    <div
      className="DndLeftNavItemSortable"
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0 : 1,
      }}
    >
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        onEntered={() => {}}
        onExited={() => {
          setItemsOnCollapse(item.parentId as number, "close");
        }}
      >
        <DndLeftNavItem item={item} dragHandleProps={{ ref: setActivatorNodeRef, attributes, listeners }} />
      </Collapse>
    </div>
  );
};

export default DndLeftNavItemSortable;
